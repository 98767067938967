<template>
  <div class="game-container">
    <DragStrokeToImgGame
      :chineseList="chineseList"
      :strokeList="strokeList"
      :bgImg="bgImg"
      :mapList="mapList"
      @handleDropEvent="handleDropEvent"
      :answersfound="answersFoundSum"
      :isReviewGame="isReviewGame"
    />
  </div>
</template>

<script>
import DragStrokeToImgGame from "@/components/Course/GamePage/DragStrokeToImgGame";
import { startConfetti, playCorrectSound } from "@/utils/tools";

export default {
  data() {
    return {
      answersFoundSum: 0,
      bgImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/background.svg"),
      isReviewGame: true,
      mapList: [
        [
          { index: 1, id: 1, type: [2] },
          { index: 4, id: 4, type: [0, 0] },
          { index: 7, id: 7, type: [0, 1] },
        ],
        [
          { index: 2, id: 2, type: [0] },
          { index: 5, id: 5, type: [0, 2] },
          { index: 8, id: 8, type: [2] },
        ],
        [
          { index: 3, id: 3, type: [1] },
          { index: 6, id: 6, type: [1, 1] },
          { index: 9, id: 9, type: [1, 2, 0] },
        ],
      ],
      strokeList: [
        {
          type: 0,
          image: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/blue.svg"),
          total: 6,
          currentSum: 0,
        },
        {
          type: 1,
          image: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/red.svg"),
          total: 5,
          currentSum: 0,
        },
        {
          type: 2,
          image: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/yellow.svg"),
          total: 4,
          currentSum: 0,
        },
      ],
      chineseList: [
        {
          id: 1,
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/yellow-4.svg"),
              type: 2,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 2,
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/blue-1.svg"),
              type: 0,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 3,
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/red-1.svg"),
              type: 1,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 4,
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/blue-2-1.svg"),
              type: 0,
              isShow: false,
            },
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/blue-2-2.svg"),
              type: 0,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 5,
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/yellow-2.svg"),
              type: 2,
              isShow: false,
            },
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/blue-3.svg"),
              type: 0,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 6,
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/red-2-1.svg"),
              type: 1,
              isShow: false,
            },
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/red-2-2.svg"),
              type: 1,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 7,
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/red-3.svg"),
              type: 1,
              isShow: false,
            },
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/blue-4.svg"),
              type: 0,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 8,
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/yellow-3.svg"),
              type: 2,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 9,
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/red-4.svg"),
              type: 1,
              isShow: false,
            },
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/yellow-1.svg"),
              type: 2,
              isShow: false,
            },
            {
              strokeImg: require("@/assets/img/16-GAMES/G55-bingo-strokes/lesson-16-bingo/blue-6.svg"),
              type: 0,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
      ],
    };
  },
  components: {
    DragStrokeToImgGame,
  },
  methods: {
    handleDropEvent(id, type) {
      console.log(id, type);
      this.chineseList.forEach((item) => {
        if (item.id === id) {
          item.strokeImgList.forEach((ele) => {
            if (ele.type === type && !item.flag) {
              if (ele.isShow) return;
              ele.isShow = true;
              item.flag = true;
              item.strokeSum++;
              console.log(item.strokeSum);
              this.handleDefaultEvent(id, type);
              this.handleEndSingleStroke(type);
            }
          });
          item.flag = false;
        }
      });
    },
    handleDefaultEvent(id, type) {
      this.mapList.forEach((j) => {
        j.forEach((k) => {
          if (k.id === id) {
            console.log(k.type);
            const typeIndex = k.type.findIndex((value) => value === type);
            k.type.splice(typeIndex, 1);
            if (!k.type.length) {
              k.isAddStyle = true;
            }
          }
        });
      });
    },
    handleEndSingleStroke(type) {
      this.strokeList.forEach((item) => {
        if (item.type === type) {
          item.currentSum++;
          if (item.currentSum === item.total) {
            startConfetti();
            playCorrectSound();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
