<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "kěyǐ ",
          hanzi: "可以",
          english: "can; may",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-ni-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "qǐng ",
          hanzi: "请",
          english: "please",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-hao-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "bú kèqi",
          hanzi: "不客气",
          english: "You're welcome",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-nihao-bubble.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "duìbuqǐ ",
          hanzi: "对不起",
          english: "I'm sorry",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-wo-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "méi guānxi ",
          hanzi: "没关系",
          english: "Never mind",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-shi-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "six",
          isShowImg: "true",
          currentNum: 6,
          pinyin: "búyào ",
          hanzi: "不要",
          english: "don't",
          image: require(`@/assets/img/12-Vocabulary/lesson-01-woshi-bubble.svg`),
          blue: true,
          // longtext: true,
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
