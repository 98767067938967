<template>
  <div class="game-container">
    <ClickImgChooseGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      :lessonNO="292"
    />
  </div>
</template>

<script>
import ClickImgChooseGame from "@/components/Course/GamePage/ClickImgChooseGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/background.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/mian.svg"),
          clickImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/mian.svg"),
          id: 1,
          pinyin:"Miàntiáor hěn hǎochī",
          hanzi:"面条儿很好吃"
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/jiaozi.svg"),
          clickImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/jiaozi.svg"),
          id: 2,
          pinyin:"Wǒ xǐhuan chī jiǎozi.",
          hanzi:"我喜欢吃饺子。"
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/kuaizi.svg"),
          clickImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/kuaizi.svg"),
          id: 3,
          pinyin:"Zhè bú shì kuàizi. ",
          hanzi:"这不是筷子。"
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/baozi.svg"),
          clickImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/baozi.svg"),
          id: 4,
          pinyin:"Wǒ yào sān ge bāozi.",
          hanzi:"我要三个包子。"
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/youtiao.svg"),
          clickImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/youtiao.svg"),
          id: 5,
          pinyin:" Dòujiāng hěn hǎohē.",
          hanzi:"豆浆很好喝。"
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/youtiao.svg"),
          clickImg: require("@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/youtiao.svg"),
          id: 6,
          pinyin:"Zhè shì yóutiáo.",
          hanzi:"这是油条。"
        },
        
      ],
       maskImgList: [
        [
        {
          bgImg: require(`@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/image-1-1.svg`),
          id:1,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/image-1-2.svg`),
          id:2,
          isTrue:true,
        },
        
       ],
       [
        {
          bgImg: require(`@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/image-2-1.svg`),
          id:3,
          isTrue:true,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/image-2-2.svg`),
          id:4,
        },
       ],
        [
        {
          bgImg: require(`@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/image-3-1.svg`),
          id:5,
          isTrue:true,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/image-3-2.svg`),
          id:6,
        },
       ],
        [
        {
          bgImg: require(`@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/image-4-1.svg`),
          id:7,
          isTrue:true,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/image-4-2.svg`),
          id:8,
        },
       ],
        [
        {
          bgImg: require(`@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/image-5-1.svg`),
          id:9,
          isTrue:true,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G72-click-reveal-ab/l2-lesson-07/image-5-2.svg`),
          id:10,
        },
       ]
      ],
    };
  },
  components: {
    ClickImgChooseGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>