<template>
  <div class="game-container">
    <DragFruitsGame
      :gameList="gameList"
      :hasAudio="false"
    />
  </div>
</template>

<script>
import DragFruitsGame from "@/components/Course/GamePage/DragFruitsGame";
export default {
  data() {
    return {
      gameList: [
        {
          optionList: [
            {
              id: 1,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/1-ant.svg"),
              isCorrect:true
            },
            {
              id: 2,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/1-hippo.svg"),
            },
            {
              id: 3,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/1-lion.svg"),
            },
          ],
          hanzi:"我很小。",
          pinyin:"Wǒ hěn xiǎo.",
          correctImg:require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/1-ant-correct.svg"),
        },
        {
          optionList: [
            {
              id: 1,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/2-cat.svg"),
            },
            {
              id: 2,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/2-mammoth.svg"),
            },
            {
              id: 3,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/2-man.svg"),
              isCorrect:true
            },
          ],
          hanzi:"我的头发很长。",
          pinyin:"Wǒ de tóufa hěn cháng.",
          correctImg:require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/2-man-correct.svg"),
        },
        {
          optionList: [
            {
              id: 1,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/3-chick.svg"),
            },
            {
              id: 2,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/3-swan.svg"),
            },
            {
              id: 3,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/3-flamingo.svg"),
              isCorrect:true
            },
          ],
          hanzi:"我的个子很高。",
          pinyin:"Wǒ de gèzi hěn gāo.",
          correctImg:require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/3-flamingo-correct.svg"),
        },
        {
          optionList: [
            {
              id: 1,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/4-owl.svg"),
              isCorrect:true
            },
            {
              id: 2,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/4-dog.svg"),
            },
            {
              id: 3,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/4-koala.svg"),
            },
          ],
          hanzi:"我的眼睛很大。",
          pinyin:"Wǒ de yǎnjing hěn dà.",
          correctImg:require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/4-owl-correct.svg"),
        },
        {
          optionList: [
            {
              id: 1,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/6-elephant.svg"),
            },
            {
              id: 2,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/5-rabbit.svg"),
              isCorrect:true
            },
            {
              id: 3,
              image: require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/6-squirrel.svg"),
            },
          ],
          hanzi:"我的耳朵很长，眼睛很小。",
          pinyin:"Wǒ de ěrduo hěn cháng, yǎnjing hěn xiǎo.",
          correctImg:require("@/assets/img/16-GAMES/G22-drag-abc/lesson-40/5-rabbit-correct.svg"),
        },      
      ],
    };
  },
  components: {
    DragFruitsGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
