<template>
  <div class="game-container">
    <DragCorrectPicGame
      :answerList="answerList"
      :optionsList="optionsList"
      @changeAnswerListStatus="changeAnswerListStatus"
      @changeOptionsListStatus="changeOptionsListStatus"
      :starNum="10"
    />
  </div>
</template>

<script>
import DragCorrectPicGame from "@/components/Course/GamePage/DragCorrectPicGame";
export default {
  data() {
    return {
      lessonId: 8,
      bgImage: require("@/assets/img/16-GAMES/G49-10-numbers/counting-2.svg"),
      answerList: [
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/counting-2.svg"),
          isShow: true,
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/counting-5.svg"),
          isShow: true,
        },
        {
          id: 7,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/counting-7.svg"),
          isShow: true,
        },
        {
          id: 9,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/counting-9.svg"),
          isShow: true,
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/counting-1.svg"),
          isShow: true,
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/counting-3.svg"),
          isShow: false,
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/counting-4.svg"),
          isShow: false,
        },
        {
          id: 6,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/counting-6.svg"),
          isShow: false,
        },
        {
          id: 8,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/counting-8.svg"),
          isShow: false,
        },
        {
          id: 10,
          image: require("@/assets/img/16-GAMES/G49-10-numbers/counting-10.svg"),
          isShow: false,
        },
      ],

      optionsList: [
        {
          id: 1,
          element: "一",
          pinyin: "yī",
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/counting-1.svg"),
        },
        {
          id: 2,
          element: "二",
          pinyin: "èr",
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/counting-2.svg"),
        },
        {
          id: 3,
          element: "三",
          pinyin: "sān",
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/counting-3.svg"),
        },
        {
          id: 4,
          element: "四",
          pinyin: "sì",
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/counting-4.svg"),
        },
        {
          id: 5,
          element: "五",
          pinyin: "wǔ",
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/counting-5.svg"),
        },
        {
          id: 6,
          element: "六",
          pinyin: "liù",
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/counting-6.svg"),
        },
        {
          id: 7,
          element: "七",
          pinyin: "qī",
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/counting-7.svg"),
        },
        {
          id: 8,
          element: "八",
          pinyin: "bā",
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/counting-8.svg"),
        },
        {
          id: 9,
          element: "九",
          pinyin: "jiǔ",
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/counting-9.svg"),
        },
        {
          id: 10,
          element: "十",
          pinyin: "shí",
          done: false,
          bgImg: require("@/assets/img/16-GAMES/G49-10-numbers/counting-10.svg"),
        },
      ],
    };
  },
  components: {
    DragCorrectPicGame,
  },
  methods: {
    changeAnswerListStatus(item) {
      // console.log(item);
      this.answerList.forEach((ele, index) => {
        if (ele.id === item) {
          ele.isShow = !ele.isShow;
          if (index + 5 < 10) {
            this.answerList[index + 5].isShow =
              !this.answerList[index + 5].isShow;
          }
        }
      });
    },
    changeOptionsListStatus(item) {
      console.log(item);
      this.optionsList.forEach((ele) => {
        if (ele.id === item) {
          ele.done = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











