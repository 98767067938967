<template>
  <div class="game-container">
    <FindElementByPicReviewGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      :background="`blue`"
    />
  </div>
</template>

<script>
import FindElementByPicReviewGame from "@/components/Course/GamePage/FindElementByPicReviewGame";
export default {
  data() {
    return {
      totalStars: 6,
      questionInfoList: [
        {
          id: 6,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-01-nihao-bubble.svg"),
        },
        {
          id: 3,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-01-wo.svg"),
        },
        {
          id: 1,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-01-hao.svg"),
        },
        {
          id: 2,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-01-ni.svg"),
        },
        {
          id: 5,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-05-zaijian.svg"),
        },
        {
          id: 4,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-03-laoshi-bubble.svg"),
        },
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            element: "谁",
            pinyin: "shéi ",
            sound: require("@/assets/audio/L1/5-Words/hao3.mp3"),
          },
          {
            id: 2,
            element: "他",
            pinyin: "tā ",
            sound: require("@/assets/audio/L1/5-Words/ni3.mp3"),
          },
          {
            id: 3,
            element: "她",
            pinyin: "tā ",
            sound: require("@/assets/audio/L1/5-Words/wo3.mp3"),
          },
        ],
        rightList: [
          {
            id: 4,
            element: "中国人",
            pinyin: "Zhōngguó rén  ",
            sound: require("@/assets/audio/a-zh/L/laoshi.mp3"),
          },
          {
            id: 5,
            element: "岁",
            pinyin: "suì ",
            sound: require("@/assets/audio/L1/5-Words/zaijian.mp3"),
          },
          {
            id: 6,
            element: "叫",
            pinyin: "jiào",
            sound: require("@/assets/audio/L1/5-Words/nihao.mp3"),
          },
        ],
      },
    };
  },
  components: {
    FindElementByPicReviewGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
