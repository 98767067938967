<template>
  <div class="game-container">
    <WriteHanziReviewPage
      :LeftImgList="LeftImgList"
      :hanziInfoList="hanziInfoList"
      :gifList="gifList"
    />
  </div>
</template>

<script>
import WriteHanziReviewPage from "@/components/Course/CoursePage/WriteHanziReviewPage";
export default {
  data() {
    return {
      hanziInfoList: [
        {
          pinyin: "wǔ",
          tipsList: ["一", "二", "三", "四", "五"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-04/hanzi-wu-grey-border.svg"),
          stepNumber: 4,
          tipsIndex: 4,
        },
        {
          pinyin: "bā",
          tipsList: ["一", "二", "三", "四", "五", "六", "七", "八"],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/hanzi-ba-grey-border.svg"),
          stepNumber: 2,
          tipsIndex: 7,
        },
        {
          pinyin: "shí",
          tipsList: [
            "一",
            "二",
            "三",
            "四",
            "五",
            "六",
            "七",
            "八",
            "九",
            "十",
          ],
          bgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/hanzi-shi-grey-border.svg"),
          stepNumber: 2,
          tipsIndex: 9,
        },
      ],
    };
  },
  computed: {
    LeftImgList() {
      const imgArr = [];
      for (let i = 1; i <= 3; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-08/image-${i}.svg`)
        );
      }
      return imgArr;
    },
    gifList() {
      let imgArrShi = [];
      for (let i = 1; i <= 2; i++) {
        imgArrShi.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/lesson-06-shi-${i}.gif`)
        );
      }
      let imgArrBa = [];
      for (let i = 1; i <= 2; i++) {
        imgArrBa.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-06/lesson-06-ba-${i}.gif`)
        );
      }
      let imgArrWu = [];
      for (let i = 1; i <= 4; i++) {
        imgArrWu.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-04/lesson-04-wu-${i}.gif`)
        );
      }
      return [imgArrWu, imgArrBa, imgArrShi];
    },
  },
  components: {
    WriteHanziReviewPage,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
