<template>
  <div class="game-container">
    <ReviewTrain
      :step="0"
      :vocabularyReviewList="vocabularyReviewList"
      :knowledgeReviewList="knowledgeReviewList"
      :pinyinWordReviewList="pinyinWordReviewList"
      :strokesReviewList="strokesReviewList"
    />
  </div>
</template>

<script>
import ReviewTrain from "@/components/Course/CoursePage/ReviewTrain";
export default {
  data() {
    return {
      vocabularyReviewList: [
        [
          {
            pinyin: "nǐ",
            hanzi: "你",
          },
          {
            pinyin: "wǒ",
            hanzi: "我",
          },
          {
            pinyin: "shì",
            hanzi: "是",
          },
          {
            pinyin: "hǎo",
            hanzi: "好",
          },
          {
            pinyin: "yī",
            hanzi: "一",
          },
          {
            pinyin: "èr",
            hanzi: "二",
          },
        ],
        [
          {
            pinyin: "sān",
            hanzi: "三",
          },
          {
            pinyin: "sì",
            hanzi: "四",
          },
          {
            pinyin: "wǔ",
            hanzi: "五",
          },
          {
            pinyin: "liù",
            hanzi: "六",
          },
          {
            pinyin: "qī",
            hanzi: "七",
          },
        ],
        [
          {
            pinyin: "bā",
            hanzi: "八",
          },
          {
            pinyin: "jiǔ",
            hanzi: "九",
          },
          {
            pinyin: "shí",
            hanzi: "十",
          },
          {
            pinyin: "lǎoshī",
            hanzi: "老师",
          },
          {
            pinyin: "zàijiàn",
            hanzi: "再见",
          },
        ],
      ],
      knowledgeReviewList: [
        [
          {
            pinyin: "nǐ",
            hanzi: "你",
          },
          {
            pinyin: "wǒ",
            hanzi: "我",
          },
          {
            pinyin: "shì",
            hanzi: "是",
          },
          {
            pinyin: "hǎo",
            hanzi: "好",
          },
          {
            pinyin: "yī",
            hanzi: "一",
          },
          {
            pinyin: "èr",
            hanzi: "二",
          },
        ],
        [
          {
            pinyin: "sān",
            hanzi: "三",
          },
          {
            pinyin: "sì",
            hanzi: "四",
          },
          {
            pinyin: "wǔ",
            hanzi: "五",
          },
          {
            pinyin: "liù",
            hanzi: "六",
          },
          {
            pinyin: "qī",
            hanzi: "七",
          },
        ],
        [
          {
            pinyin: "bā",
            hanzi: "八",
          },
          {
            pinyin: "jiǔ",
            hanzi: "九",
          },
          {
            pinyin: "shí",
            hanzi: "十",
          },
          {
            pinyin: "lǎoshī",
            hanzi: "老师",
          },
          {
            pinyin: "zàijiàn",
            hanzi: "再见",
          },
        ],
      ],
      pinyinWordReviewList: [
        [
          {
            vocabulary: "a",
          },
          {
            vocabulary: "o",
          },
          {
            vocabulary: "e",
          },
          {
            vocabulary: "i",
          },
          {
            vocabulary: "u",
          },
          {
            vocabulary: "ü",
          },
        ],
        [
          {
            vocabulary: "ā",
          },
          {
            vocabulary: "á",
          },
          {
            vocabulary: "ǎ",
          },
          {
            vocabulary: "à",
          },
        ],
      ],
      strokesReviewList: [
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/stroke-dian-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/stroke-hengpie-white.svg"),
          },
        ],
        [
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-12-1/hengzhegou-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-14-1/piedian-white.svg"),
          },
        ],
      ],
    };
  },
  components: {
    ReviewTrain,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>