<template>
  <!-- layout-container lesson 2 -->
  <div class="lesson72-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageB />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 全局白板部分 -->
      <WhiteBoard />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";

import SyllabusPageB from "./3_SyllabusPage_B.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";

import ReviewOne from "./4_ReviewOne.vue";
import PracticePageOne from "./5_PracticePageOne";
import PracticePageTwo from "./6_PracticePageTwo";
import PracticePageThree from "./7_PracticePageThree";

import ReviewTwo from "./8_ReviewTwo.vue";
import PracticePageFour from "./9_PracticePageFour";
import PracticePageFive from "./10_PracticePageFive";
import PracticePageSix from "./11_PracticePageSix";
import PracticePageSeven from "./12_PracticePageSeven";

import ReviewThree from "./13_ReviewThree.vue";
import PracticePageEight from "./14_PracticePageEight";
import PracticePageNine from "./15_PracticePageNine";

import ReviewFour from "./16_ReviewFour.vue";
import PracticePageTen from "./17_PracticePageTen";
import PracticePageEleven from "./18_PracticePageEleven";
import PracticePageTwelve from "./19_PracticePageTwelve";
import PracticePageThirteen from "./20_PracticePageThirteen";
import PracticePageFourteen from "./21_PracticePageFourteen";

import ReviewFive from "./22_ReviewFive.vue";
import WordsCardPage from "./23_WordsCardPage.vue";
import RankMapPageTwo from "./24_RankMapPageTwo.vue";
import ByePage from "./25_ByePage.vue";
export default {
  name: "Lesson8",
  components: {
    SyllabusPageB,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    ReviewOne,
    PracticePageOne,
    PracticePageTwo,
    PracticePageThree,
    ReviewTwo,
    PracticePageFour,
    PracticePageFive,
    PracticePageSix,
    PracticePageSeven,
    ReviewThree,
    PracticePageEight,
    PracticePageNine,
    ReviewFour,
    PracticePageTen,
    PracticePageEleven,
    PracticePageTwelve,
    PracticePageThirteen,
    PracticePageFourteen,
    ReviewFive,
    WordsCardPage,
    RankMapPageTwo,
    ByePage,
  },
  mixins: [lessonMixin],
  data() {
    return {
      pageNum: 1,
      // lesson2 中的所有page
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        ReviewOne,
        PracticePageOne,
        PracticePageTwo,
        PracticePageThree,
        ReviewTwo,
        PracticePageFour,
        PracticePageFive,
        PracticePageSix,
        PracticePageSeven,
        ReviewThree,
        PracticePageEight,
        PracticePageNine,
        ReviewFour,
        PracticePageTen,
        PracticePageEleven,
        PracticePageTwelve,
        PracticePageThirteen,
        PracticePageFourteen,
        ReviewFive,
        WordsCardPage,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson72-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
