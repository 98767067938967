<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Zǒng fùxí (yī)",
        hanzi: "总复习（一）",
        english: "Chapter 1 Review",
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>